import React, { useEffect } from 'react';
import Contacts from '../utils/images/contact.png';
import Call from '../utils/images/call.png';
import Mail from '../utils/images/mail.png';
import Locate from '../utils/images/akar-icons_location.png';
import Hero from '../utils/images/aboutus.png';

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
})
  return (
    <div>
      <section className='relative'>
            <div>
                <img src={Hero} alt="hero" className='w-full object-cover h-[50vh] lg:h-full ' />
            </div>

            <div className='space-y-5 lg:space-y-10 absolute top-32 lg:top-14 xl:top-32 px-2 lg:px-14 xl:px-28 text-center text-white'>
              <h1 className='text-3xl lg:text-5xl xl:text-7xl font-bold'>Showcasing Excellence in Every Project</h1>
              <p className='lg:text-2xl'>Delivering excellence across civil engineering, oil and gas, and global project management. Our expertise drives innovation, fuels progress, and builds a sustainable future.</p>
            </div>
        </section>

      <section className="px-5 lg:px-14 xl:px-28 flex flex-col xl:flex-row justify-between mt-24 w-full max-w-[1200px] xl:max-w-full">
        <section className="bg-[#FFE1E2] rounded-xl px-8 py-8 w-full lg:max-w-[1046px]">
          <div>
            <h1 className="text-[#A02B2D] font-bold text-[32px] lg:text-[55px] mt-2 lg:w-[570px]">
              Ready to Get Started? Let’s chat
            </h1>
          </div>

          <form className="space-y-8 lg:space-y-10 flex-grow">
            <div className="lg:flex justify-between gap-10 space-y-8 lg:space-y-0 mt-5 flex-wrap lg:flex-nowrap">
              <div className="w-full lg:w-[458px] ">
                <label className="text-lg font-medium">Full name</label>
                <div className="h-[72px] px-3 py-3 border border-[#000000] mt-1">
                  <input
                    type="text"
                    placeholder="Enter your Full name"
                    className="outline-none w-full bg-transparent"
                  />
                </div>
              </div>

              <div className="w-full lg:w-[458px] mt-5 lg:mt-0">
                <label className="text-lg font-medium">Email</label>
                <div className="h-[72px] px-3 py-3 border border-[#000000] mt-1">
                  <input
                    type="email"
                    placeholder="Enter your Email"
                    className="outline-none w-full bg-transparent"
                  />
                </div>
              </div>
            </div>

            <div className="lg:flex justify-between gap-10 space-y-8 lg:space-y-0">
              <div className="w-full lg:w-[458px]">
                <label className="text-lg font-medium">Phone Number</label>
                <div className="h-[72px] px-3 py-3 border border-[#000000] mt-1">
                  <input
                    type="text"
                    placeholder="Enter your Phone Number"
                    className="outline-none w-full bg-transparent"
                  />
                </div>
              </div>

              <div className="w-full lg:w-[458px] lg:mt-10">
                <label className="text-lg font-medium pt-10">Subject</label>
                <div className="h-[72px] px-3 py-3 border border-[#000000] mt-">
                  <input
                    type="text"
                    placeholder="Enter your Subject"
                    className="outline-none w-full bg-transparent"
                  />
                </div>
              </div>
            </div>

            <div className="mt-3 w-full">
              <label className="text-lg font-medium">Message</label>
              <div className="outline-none h-[130px] px-3 py-3 border border-[#000000] mt-3">
                <textarea
                  placeholder="Enter your Message"
                  className="w-full h-full outline-none bg-transparent"
                ></textarea>
              </div>
            </div>

            <div className="mt-5 flex justify-center">
              <button className="text-white bg-[#A02B2D] rounded-xl w-full lg:w-[394px] h-[65px] font-bold text-lg">
                Send a message
              </button>
            </div>
          </form>
        </section>

        <section className="space-y-5 mt-10 xl:mt-0 lg:ml-10">
          <div className="border border-[#000000] px-10 py-8 w-full xl:w-[412px] h-[230px] space-y-5 text-[#000000] rounded-xl">
            <img src={Call} alt="Call" />
            <p className="text-xl font-bold">Call support Center 24/7</p>
            <div className="">
              <p>+234 (0) 805 943 8246</p>
            </div>
          </div>

          <div className="border border-[#000000] px-10 py-8 w-full xl:w-[412px] h-[230px] space-y-5 text-[#000000] rounded-xl ">
            <img src={Mail} alt="Mail" />
            <p className="text-xl font-bold ">Write to us</p>
            <div className="">
              <p className="underline">veekites1@gmail.com</p>
            </div>
          </div>

          <div className="border border-[#000000] px-10 py-8 w-full xl:w-[412px] h-[230px] space-y-5 text-[#000000] rounded-xl ">
            <img src={Locate} alt="Locate" />
            <p className="text-xl font-bold">Visit us</p>
            <div className="">
              <p>PLOT 141 MEIMOGHA LAYOUT, OKWISOKO,
              OFF JAKPA ROAD EFFURUN, DELTA STATE</p>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default Contact;
