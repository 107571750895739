import React, { useEffect } from "react";
import Hero from "../utils/images/hero.jpg";
import Work from "../utils/images/work.png";
import First from "../utils/images/first.png";
import { Projects, Others, News, FAQs, Brands } from "../utils/Data";
import Under from "../utils/images/under.png";
import Gallery from "./Gallery";
import Map from "../utils/images/map.png";
import Stars from "../utils/images/star.png";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <section>
        <div
          className="relative z-0 w-full h-[60vh] lg:h-screen bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${Hero})` }}
        >
          <div className="absolute z-[-1] top-0 left-0 w-full h-full bg-black opacity-50 "></div>
        </div>

        <div className="absolute top-[20%] lg:top-[50%] text-center lg:text-start px-3 lg:px-28 text-white space-y-7">
          <h1 className="font-bold text-2xl lg:text-[45px] xl:text-[82px] lg:leading-[95px] xl:w-[988px]">
            Engineering Tomorrow's Solutions, Today.
          </h1>
          <p className="text-lg lg:text-2xl lg:w-[900px] xl:w-[988px]">
            Delivering excellence across civil engineering, oil and gas, and
            global project management. Our expertise drives innovation, fuels
            progress, and builds a sustainable future.
          </p>
          <div className="space-x-5 hidden lg:block">
            <input
              type="email"
              name=""
              id=""
              className="px-5 py-2 w-[500px] rounded-full h-[60px] text-black outline-none text-xl"
              placeholder="Enter your email"
            />
            <button className="px-5 py-3 h-[60px] font-bold text-lg rounded-full bg-[#A02B2D]">
              Request Consultation
            </button>
          </div>

          <div className="flex bg-white py-2 px-2 rounded-full lg:hidden flex-grow">
            <input
              type="email"
              name=""
              id=""
              placeholder="Enter your email"
              className="bg-transparent text-black w-[55%] outline-none flex-grow"
            />{" "}
            <button className="px-3 py-2 h-[60px] font-bold text-sm rounded-full bg-[#A02B2D]">
              Request Consultation
            </button>
          </div>
          <p>+2K Employee!</p>
        </div>
      </section>

      <section className=" bg-black text-white py-5">
        <div className="px-3 lg:px-20 xl:px-24 flex justify-between">
          <div>
            <p className="lg:text-[30px] xl:text-[40px] lg:leading-[55px] text-base font-semibold xl:w-[850px]">
              “Excellence in every project, innovation in every solution. We
              deliver the future, today.”
            </p>
          </div>

          <div className="flex space-x-4">
            <p className=" w-[80px] lg:w-[112px] h-[5opx] flex justify-center items-center bg-[#A02B2D] rounded-full">
              <img src={Work} alt="" />
            </p>
          </div>
        </div>
      </section>

      <section className="px-2 ml-5 lg:ml-0 lg:px-14 mt-14 lg:mt-24 lg:flex-nowrap flex-wrap gap-10 xl:gap-0  flex justify-between">
        <div className="space-y-5">
          <h1 className="lg:w-[500px] xl:w-[720px] text-[27px] lg:text-[40px] xl:text-[55px] font-bold text-[#A02B2D]">
            Committed to Innovation, Quality,& Global Impact
          </h1>
          <p className="lg:w-[500px] xl:w-[724px] text-lg lg:text-lg xl:text-2xl leading-9 lg:leading-[45px] font-medium">
            Veekites global services limited is widely recognized as one of the
            most professional companies offering a wide range of services in the
            fields of engineering, construction, supplies, and training, with a
            team of directors and senior executives who are experts in their
            chosen fields. Each member of this formidable team offers a range of
            combined experience in their areas of specialty.
          </p>
        </div>

        <div className="flex flex-col justify-center items-center relative mt-14 lg:mt-0">
          <p className="z-40 relative">
            <img
              src={First}
              alt=""
              className="w-[80%] lg:w-[94%] mx-auto relative z-20"
            />
          </p>

          <div className="w-full relative -mt-8 lg:-mt-24 z-50">
            <div className="bg-black px-3 lg:px-10 py-2 space-y-1 -mt-60 lg:-mt-96 absolute left-[10px] lg:left-[-40px] text-white rounded-lg text-center transform">
              <p className="text-xs lg:text-base">Project Completion</p>
              <h1 className="text-2xl lg:text-5xl font-bold">98%</h1>
            </div>
            <div className="bg-[#A02B2D] px-3 lg:px-5 py-2 space-y-1 absolute  bottom-[150%] right-[10px] lg:right-[-40px] text-white rounded-lg text-center transform">
              <p className="text-xs lg:text-base">Growth</p>
              <h1 className="text-2xl lg:text-5xl font-bold">98%</h1>
            </div>
          </div>

          <p className="absolute top-[105%] left-0 transform -translate-y-8 w-full z-10">
            <img src={Under} alt="" className="w-[87%] lg:w-full mx-auto" />
          </p>
        </div>
      </section>

      <Gallery />

      <section className="mt-24 px-5 lg:px-14 xl:px-28">
        <div className="text-center space-y-4">
          <h1 className="text-[32px] lg:text-[55px] font-semibold text-[#A02B2D]">
            Our Projects/portfolio
          </h1>
          <p className="text-lg lg:text-2xl">
            Delivering Excellence Across Industries
          </p>
        </div>
        <section className="flex justify-center items-center lg:justify-between flex-wrap lg:flex-nowrap gap-10 mt-14 flex-grow">
          {Projects.slice(0, 3).map((pro, index) => (
            <div key={pro.id} className="space-y-4 flex-grow">
              <p>
                <img
                  src={pro.img}
                  alt=""
                  className={`${
                    index === 0 || index === 2 ? "mt-10" : ""
                  } w-full h-auto object-cover flex-grow`}
                  style={{ width: "489px", height: "300px" }} // Consistent width/height
                />
              </p>
              <p className="font-epilogue font-semibold">{pro.title}</p>
              <p>{pro.summ}</p>

              <p className="underline text-[#A02B2D] text-lg">
                {" "}
                <a href="/home">View project </a>{" "}
              </p>
            </div>
          ))}
        </section>
        <section className="text-center flex justify-center mt-20">
          <button className="bg-[#A02B2D] px-7 text-white rounded-full py-3">
            See more Projects
          </button>
        </section>
      </section>

      <section className="px-5 lg:px-14 xl:px-28 mt-24">
        <div className="text-center space-y-5">
          <h1 className="text-[32px] lg:text-[55px] font-semibold text-[#A02B2D]">
            Our Global Reach
          </h1>
          <p className="text-xl lg:text-2xl">Our Global Reach</p>
          <p className="text-base lg:text-2xl leading-8 lg:leading-[55px]">
            With a strategic presence across the globe, our company is at the
            forefront of delivering innovative solutions that make a difference
            in communities worldwide. From urban infrastructure in bustling
            cities to energy projects in remote locations, our expertise knows
            no borders. Explore our global footprint and discover how we’re
            driving progress in every corner of the world.
          </p>
        </div>
        <div className="mt-14">
          <img src={Map} alt="" className="w-full h-[629px] object-cover" />
        </div>

        <section className="flex justify-between">
          <div className="space-y-3 text-center">
            <h1 className="text-[32px] lg:text-6xl text-[#A02B2D] font-bold">
              4.5k+
            </h1>
            <p>clients across the world</p>
          </div>
          <div className="space-y-3 text-center">
            <h1 className="text-[32px] lg:text-6xl  text-[#A02B2D] font-bold">
              1.5k+
            </h1>
            <p>Completed Projects</p>
          </div>
          <div className="space-y-3 text-center">
            <h1 className="text-[32px] lg:text-6xl  text-[#A02B2D] font-bold">
              100+
            </h1>
            <p>Reached Countires</p>
          </div>
        </section>
      </section>

      <section className="hidden lg:px-14 xl:px-28 mt-24 lg:block">
        <div className="text-center">
          <h1 className="text-[32px] lg:text-[55px] font-semibold text-[#A02B2D]">
            What others are saying about Us
          </h1>
        </div>

        <section className="flex lg:justify-between flex-wrap lg:flex-nowrap mt-14">
          {Others.map((say) => (
            <div className=" space-y-5 w-[500px] mt-10 lg:mt-0">
              <p>
                <img src={say.img} alt="" />
              </p>
              <h1 className="text-3xl font-medium">{say.title}</h1>
              <p className="leading-10 text-xl"> {say.summ}</p>
              <p>
                <img src={Stars} alt="" />
              </p>
              <p className="text-lg">{say.pro}</p>
            </div>
          ))}
        </section>
      </section>

      {/* For mobile */}
      <section className="lg:hidden mt-24 px-5 flex-grow">
        <div className="text-center">
          <h1 className="text-[32px] lg:text-[55px] font-semibold text-[#A02B2D]">
            What others are saying about Us
          </h1>
        </div>

        <section className="flex lg:justify-between flex-wrap lg:flex-nowrap mt-5 flex-grow">
          {Others.slice(0, 1).map((say) => (
            <div className="flex flex-col justify-center items-center space-y-5 w-[500px] mt-10 lg:mt-0 flex-grow">
              <p>
                <img src={say.img} alt="" />
              </p>
              <h1 className="text-3xl font-medium">{say.title}</h1>
              <p className="leading-10 text-xl"> {say.summ}</p>
              <p className="text-lg">{say.pro}</p>
            </div>
          ))}
        </section>
      </section>
      {/* End  */}

      <section className="px-5 lg:px-14 xl:px-28 mt-24">
        <div className="text-center space-y-5">
          <h1 className="text-[32px] lg:text-[55px] font-semibold text-[#A02B2D]">
            Brands We have worked with
          </h1>
          <p className="text-lg lg:text-2xl">
            Partnering with Leading Brands Across Industries
          </p>
        </div>
      </section>

      <section className="bg-[#FFE1E2] mt-14">
        <div className="px-5 lg:px-14 xl:px-28 py-14 flex justify-around gap-5 lg:gap-0  lg:justify-between flex-wrap lg:flex-nowrap">
          {Brands.map((brand) => (
            <div>
              <img src={brand.img} alt="" className="mt-5 lg:mt-0" />
            </div>
          ))}
        </div>
      </section>

      <section className="px-5 lg:px-14 xl:px-28 mt-24">
        <section className="flex justify-between gap-10 flex-wrap lg:flex-nowrap">
          <div className="space-y-5 w-[679px]">
            <h1 className="text-[32px] lg:text-[45px] font-semibold text-[#A02B2D]">
              Frequently asked Questions
            </h1>
            <p className="text-lg lg:text-2xl">
              Find Answers to Your Common Queries
            </p>
            <button className="px-7 py-3 rounded-full bg-[#A02B2D] text-white font-bold text-lg">
              Try for free
            </button>
          </div>

          <section className="xl::w-[800px] mt-10 lg:mt-0 flex-grow">
            {FAQs.map((faq) => (
              <div className="border-b lg:border-t xl:w-[827px] flex justify-between py-10 flex-grow">
                <p className="text-xl lg:text-[32px]">{faq.title}</p>
                <p className="text-2xl">+</p>
              </div>
            ))}
          </section>
        </section>
      </section>

      <section className="px-5 lg:px-14 xl:px-28 mt-24">
        <section className="flex justify-between">
          <div className="space-y-5 w-[732px]">
            <h1 className="text-[32px] lg:text-[55px] font-semibold text-[#A02B2D]">
              News & Insights
            </h1>
            <p className="text-lg lg:text-2xl text-[#66697F]">
              Discover articles and tutorials to help you build better
            </p>
          </div>

          <section>
            <button className="border px-4 py-2 hidden lg:block">
              Browse all
            </button>
          </section>
        </section>

        <section className="flex justify-between gap-5 lg:mt-14 flex-wrap lg:flex-nowrap">
          {News.map((news) => (
            <div className="border rounded-lg space-y-5  w-[500px] py-5 px-5 mt-10 lg:mt-0 flex-grow">
              <p className="flex justify-center">
                <img
                  src={news.img}
                  alt=""
                  className="flex-grow max-h-[230px]"
                />
              </p>
              <div className="flex justify-start">
                <p className="border text-start  px-2 text-[#4B6BFB] py-1 text-sm">
                  {news.category}
                </p>
              </div>
              <h1 className="font-semibold text-2xl">{news.title}</h1>
              <div className="flex space-x-4">
                <p>
                  <img
                    src={news.proImg}
                    alt=""
                    className="h-24 w-fit object-contain"
                  />
                </p>
                <p className="mt-1 text-[#97989F]">{news.name}</p>
                <p className="mt-1 text-[#97989F]">{news.detail}</p>
              </div>
            </div>
          ))}
        </section>
      </section>
    </div>
  );
}

export default Home;
