import React, { useEffect } from "react";
import Pro1 from "../utils/images/newPro.png";
import Pro2 from "../utils/images/newpro1.png";
import Pro3 from "../utils/images/newpro2.png";
import Pro4 from "../utils/images/proPage4.png";
import Hero from "../utils/images/aboutus.png";
import Gallerys from "./Gallerys";
import { Outlet, useLocation, Link } from "react-router-dom";
import { Others } from "../utils/Data";
import Stars from "../utils/images/star.png";

function Projects() {
  const Location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Outlet />

      {Location.pathname === "/project" && (
        <div>
          <section className="relative">
            <div>
              <img
                src={Hero}
                alt="hero"
                className="w-full object-cover h-[50vh] lg:h-full"
              />
            </div>

            <div className="space-y-5 lg:space-y-10 absolute top-32 lg:top-14 xl:top-32 px-2 lg:px-28 text-center text-white">
              <h1 className="text-3xl lg:text-5xl xl:text-7xl font-bold">
                Showcasing Excellence in Every Project
              </h1>
              <p className="lg:text-2xl">
                Delivering excellence across civil engineering, oil and gas, and
                global project management. Our expertise drives innovation,
                fuels progress, and builds a sustainable future.
              </p>
            </div>
          </section>

          <section className="lg:px-14 xl:px-28 px-3 mt-14 lg:mt-24 flex justify-between gap-10 lg:gap-32 flex-wrap xl:flex-nowrap">
            <div className="space-y-5  mt-5">
              <h1 className="text-[27px] lg:text-[40px] xl:w-[720px] xl:text-[55px] text-[#A02B2D] font-bold">
                Delivering Impactful Solutions Across Industries
              </h1>
              <p className="xl:w-[724px] text-lg leading-9 lg:text-2xl lg:leading-[55px] font-medium">
                Veekites global services limited is widely recognized as one of
                the most professional companies offering a wide range of
                services in the fields of engineering, construction, supplies,
                and training, with a team of directors and senior executives who
                are experts in their chosen fields. Each member of this
                formidable team offers a range of combined experience in their
                areas of specialty.
              </p>
            </div>

            <div className="flex flex-wrap justify-between gap-5 lg:gap-10">
              <p className="flex-grow">
                <img src={Pro1} alt="" className="w-full flex-grow" />
              </p>
              <p className="lg:mt-5 flex-grow">
                <img src={Pro2} alt="" className="w-full flex-grow" />
              </p>
              <p className="flex-grow">
                <img src={Pro3} alt="" className="w-full flex-grow" />
              </p>
              <p className="lg:mt-5 flex-grow">
                <img src={Pro4} alt="" className="w-full flex-grow" />
              </p>
            </div>
          </section>

          <section className="lg:px-32 mt-24 px-3">
            <div className="text-center">
              <h1 className="text-[27px] lg:text-[40px] xl:text-[45px] font-bold text-[#A02B2D]">
                Featured Projects
              </h1>
              <p className="text-lg leading-9 xl:text-2xl xl:leading-[55px] font-medium">
                Explore a selection of our most impactful projects, where
                innovation meets excellence. Each project reflects our
                dedication to delivering tailored solutions that drive success
                across various industries.
              </p>
            </div>
          </section>

          <section>
            <Gallerys />
          </section>

          <section className="hidden lg:px-14 xl:px-28 mt-24 lg:block">
            <div className="text-center">
              <h1 className="text-[32px] lg:text-[40px] xl:text-[55px] font-semibold text-[#A02B2D]">
                What others are saying about Us
              </h1>
            </div>

            <section className="flex lg:justify-between flex-wrap lg:flex-nowrap mt-14 gap-5">
              {Others.map((say) => (
                <div className=" space-y-5 w-[500px] mt-10 lg:mt-0">
                  <p>
                    <img src={say.img} alt="" />
                  </p>
                  <h1 className="text-3xl font-medium">{say.title}</h1>
                  <p className="leading-10 text-xl"> {say.summ}</p>
                  <p>
                    <img src={Stars} alt="" />
                  </p>
                  <p className="text-lg">{say.pro}</p>
                </div>
              ))}
            </section>
          </section>

          {/* For mobile */}
          <section className="lg:hidden mt-24 px-5 flex-grow">
            <div className="text-center">
              <h1 className="text-[32px] lg:text-[55px] font-semibold text-[#A02B2D]">
                What others are saying about Us
              </h1>
            </div>

            <section className="flex lg:justify-between flex-wrap lg:flex-nowrap mt-5 flex-grow">
              {Others.slice(0, 1).map((say) => (
                <div className="flex flex-col justify-center items-center space-y-5 w-[500px] mt-10 lg:mt-0 flex-grow">
                  <p>
                    <img src={say.img} alt="" />
                  </p>
                  <h1 className="text-3xl font-medium">{say.title}</h1>
                  <p className="leading-10 text-xl"> {say.summ}</p>
                  <p className="text-lg">{say.pro}</p>
                </div>
              ))}
            </section>
          </section>
          {/* End  */}
        </div>
      )}
    </div>
  );
}

export default Projects;
