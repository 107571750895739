import React, { useEffect } from "react";
import CareerImg from "../utils/images/career.png";
import Hero from "../utils/images/aboutus.png";
import First from "../utils/images/career1.png";
import Group from "../utils/images/group.png";
import Location from "../utils/images/location.png";
import { WorkWithUs, Positions } from "../utils/Data";
import { FaCheckCircle } from "react-icons/fa";
import { Outlet, useLocation, Link } from "react-router-dom";

function Career() {
  const Locations = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <Outlet />

      {Locations.pathname === "/career" && (
        <div className="w-full">
          <section className="relative">
            <div>
              <img
                src={Hero}
                alt="hero"
                className="w-full object-cover h-[50vh] lg:h-full"
              />
              <div className="absolute inset-0 bg-black opacity-50 lg:hidden"></div>
            </div>

            <div className="space-y-5 lg:space-y-10 absolute top-32 lg:top-14 xl:top-32 px-2 lg:px-44 text-center text-white">
              <h1 className="text-3xl lg:text-5xl xl:text-7xl font-bold">
                Join Our Team and Build the Future with Us
              </h1>
              <p className="lg:text-2xl">
                Be a part of a dynamic, innovative, and growth-focused company
                where your ideas and contributions matter.
              </p>
            </div>
          </section>

          <section className="px-3 lg:px-14 xl:px-28 mt-14 lg:mt-24 flex flex-col xl:flex-row justify-between items-center lg:items-start">
            <div className="space-y-5 xl:w-1/2">
              <h1 className="text-[27px] lg:text-[40px] xl:text-[55px] font-bold text-[#A02B2D]">
                Discover Your Next Career Opportunity with Us
              </h1>
              <p className="text-lg leading-9 lg:text-2xl lg:leading-[55px] font-medium">
                Veekites global services limited is widely recognized as one of
                the most professional companies offering a wide range of
                services in the fields of engineering, construction, supplies,
                and training, with a team of directors and senior executives who
                are experts in their chosen fields. Each member of this
                formidable team offers a range of combined experience in their
                areas of specialty.
              </p>
            </div>

            <div className="mt-10 xl:mt-0 xl:w-1/2 flex justify-center">
              <img src={First} alt="" className="w-full max-w-xs lg:max-w-md" />
            </div>
          </section>

          <section className="px-5 lg:px-14 xl:px-28 mt-28 flex flex-col xl:flex-row justify-between items-center lg:items-start">
            <section className="bg-[#E4E4E7] max-w-[1200px] xl:max-w-full w-full lg:w-[622px] h-[529px] shadow-2xl rounded-xl mt-10 xl:mt-40">
              <section className="bg-[#E4E4E7] w-full h-[52px] rounded-t-xl flex justify-start gap-3 px-10 pt-5 border border-[#bdbaba]">
                <p className="bg-[#EF4444] w-[15px] h-[15px] rounded-full"></p>
                <p className="bg-[#000000] w-[15px] h-[15px] rounded-full"></p>
                <p className="bg-[#2BA275] w-[15px] h-[15px] rounded-full"></p>
              </section>
              <section className="bg-white h-[490px] w-full px-10 pt-20 space-y-14 rounded-b-xl border border-[#bdbaba]">
                <p>
                  <img src={Group} alt="" />
                </p>

                <div className="bg-[#E4E4E7] px-3 py-5 rounded-xl">
                  <p className="text-lg font-medium">
                    Join a company where your work makes a difference. We tackle
                    challenging projects that drive positive change.
                  </p>
                </div>

                <div className="space-y-3">
                  <p className="bg-[#F5F5F5] w-full lg:w-[455px] h-[16px] rounded-xl"></p>
                  <p className="bg-[#F5F5F5] w-[250px] lg:w-[350px] h-[16px] rounded-xl"></p>
                </div>
              </section>
            </section>

            <section className="mt-10 xl:mt-0 xl:ml-10">
              <div>
                <h1 className="text-[32px] lg:text-[40px] xl:text-[55px] font-bold text-[#A02B2D] text-center lg:text-left">
                  Why Work With Us?
                </h1>
              </div>
              <section>
                {WorkWithUs.map((work) => (
                  <div
                    className="flex justify-between gap-5 px-5 h-auto xl:h-[152px] py-6 rounded-xl bg-[#FFE1E2] mt-5"
                    key={work.id}
                  >
                    <p>
                      <FaCheckCircle className="text-2xl mt-2" />
                    </p>
                    <div className="space-y-2">
                      <h1 className="text-2xl font-bold">{work.title}</h1>
                      <p className="text-lg w-full lg:w-[623px]">{work.summ}</p>
                    </div>
                  </div>
                ))}
              </section>
            </section>
          </section>

          <section className="px-5 xl:px-28 lg:px-14 mt-24 w-full">
            <div className="text-center lg:text-left">
              <h1 className="text-[#A02B2D] font-bold text-[32px] lg:text-[55px]">
                Open positions
              </h1>
            </div>

            <section className="flex flex-wrap justify-center lg:justify-between mt-10">
              {Positions.map((posi) => (
                <div
                  className="w-full lg:w-[483px] h-auto lg:h-[593px] py-14 px-5 mt-5 space-y-10 border border-[#A02B2D]"
                  key={posi.id}
                >
                  <div className="flex justify-between">
                    <h1 className="text-2xl font-bold">{posi.position}</h1>
                    <p className="border px-2 py-3">{posi.type}</p>
                  </div>

                  <div className="flex space-x-4 items-center">
                    <img src={Location} alt="" />
                    <p className="text-lg">{posi.location}</p>
                  </div>
                  <p className="leading-[36px]">{posi.description}</p>

                  <div>
                    <Link to="job">
                      <button className="bg-[#A02B2D] w-full h-[65px] rounded-full font-bold text-white">
                        Apply Now
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </section>
          </section>
        </div>
      )}
    </div>
  );
}

export default Career;
